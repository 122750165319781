import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/auth-guard.guard";
import { translations } from "./config/translations/translations";

const routes: Routes = [
  {
    path: "insert-key",
    loadChildren: () => import("./insert-key/insert-key.module").then((m) => m.InsertKeyModule),
    data: { roles: ["ROLE_ENERGY_MONITORING_KS2_OPERATOR"] },
    canActivate: [AuthGuard],
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "insert-key",
  },
  {
    path: "**",
    redirectTo: "insert-key",
  },
  {
    path: "unauthorized",
    loadChildren: () => import("@bfl/components/unauthorized").then(m => m.BfcUnauthorizedModule.asChild(translations)),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
