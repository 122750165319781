import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  BfcAuthenticationInterceptor,
  BfcAuthenticationModule,
  BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
} from "@bfl/components/authentication";
import {
  BfcConfigurationModule,
  BFC_CONFIGURATIONS,
} from "@bfl/components/configuration";
import {
  BfcTranslationModule,
  BFC_TRANSLATIONS,
} from "@bfl/components/translation";
import { BfcBasePageLayoutModule } from "@bfl/components/base-page-layout";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { authenticationInterceptorWhitelist } from "./config/authentication-interceptor-whitelist";
import { configuration } from "./config/configuration";
import { translations } from "./config/translations/translations";
import { CoreModule } from "./core/core.module";
import { CommonModule } from "@angular/common";
import { BfcLinkModule } from "@bfl/components/link";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { BfcNotificationModule } from "@bfl/components/notification";
import { BfcUnauthorizedModule } from "@bfl/components/unauthorized";

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    BfcTranslationModule,
    BfcUnauthorizedModule,
    BfcConfigurationModule,
    BfcAuthenticationModule,
    BfcBasePageLayoutModule,
    BfcLinkModule,
    BfcSinglePageLayoutModule,
    BfcNotificationModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BfcAuthenticationInterceptor,
      multi: true,
    },
    {
      provide: BFC_AUTHENTICATION_INTERCEPTOR_WHITELIST,
      useValue: authenticationInterceptorWhitelist,
    },
    {
      provide: BFC_TRANSLATIONS,
      useValue: translations,
      multi: true,
    },
    {
      provide: BFC_CONFIGURATIONS,
      useValue: configuration,
      multi: true,
    },
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
