import { Component, HostBinding } from "@angular/core";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  @HostBinding("class")
  classes = "bfc-base";

  title = "ks2-energy-monitoring-webapp";
}
